import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Green from '../images/assets/greenSquiggle.png';
import Red from '../images/assets/redSquiggle.png';
import Purple from '../images/assets/purpleSquiggle.png';
import { motion } from 'framer-motion';
import convertHtml from '../utils/convertHtml';

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, #f1f1f1 0%, rgba(255, 255, 255, 0) 100%);
`;

const ErrorContainerInner = styled(motion.div)`
  margin-bottom: 50px;
  margin-top: 50px;
`;

const Title = styled.div`
  font-family: 'DM Serif Text', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 200px;
  line-height: 200px;
  text-align: center;
  color: #404040;
  background-color: #212121;
  background-image: url(${props => props.backgroundWave});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 735px) {
    font-size: 150px;
    line-height: 150px;
  }
  @media (max-width: 400px) {
    font-size: 100px;
    line-height: 100px;
  }
`;

const Description = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 64px;
  line-height: 64px;
  text-align: center;
  margin-top: 50px;
  color: #404040;

  @media (max-width: 735px) {
    font-size: 45px;
    margin-top: 0;
  }
  @media (max-width: 400px) {
    font-size: 35px;
    line-height: 45px;
  }
`;

const SmallerDesc = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 35px;
  text-align: center;
  color: #404040;
  opacity: 0.7;
  margin-top: 50px;
  max-width: 1200px;
  width: 90%;
  margin-left: 5%;
  span {
    opacity: 1;
    font-weight: bold;
  }
  @media (max-width: 735px) {
    margin-top: 50px;
    font-size: 16px;
    line-height: 28px;
  }
`;

const SmallerDescText = styled.div`
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 35px;
  text-align: center;
  color: #404040;
  opacity: 0.7;
  margin-top: 50px;
  max-width: 1200px;
  width: 90%;
  white-space: pre-wrap;
  margin-left: 5%;
  span {
    opacity: 1;
    font-weight: bold;
  }
  @media (max-width: 735px) {
    margin-top: 0;
    font-size: 16px;
    line-height: 28px;
  }
`;

const ReturnLink = styled.div`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 40px;
`;

const Squiggles = [Green, Red, Purple];

const NotFoundPage = props => {
  let randomnumber = Math.floor(Math.random() * (2 - 0 + 1)) + 0;
  return (
    <Layout
      menu_background={'true'}
      header_items_color={'#ffffff'}
      mobile_header_items_color={'#ffffff'}
      fixed_background_transparent_mode={'false'}
      fixed_background_color={'#fff'}
      fixed_menu_items_color={'#ffffff'}
      fixed_menu_logo={props.data.allPrismic404PageSettings.nodes[0].data.logo}
      logo={props.data.allPrismic404PageSettings.nodes[0].data.logo}
      footer_logo={
        props.data.allPrismic404PageSettings.nodes[0].data.footer_logo
      }
      mobile_logo={
        props.data.allPrismic404PageSettings.nodes[0].data.mobile_logo
      }
      fixed_mobile_logo={
        props.data.allPrismic404PageSettings.nodes[0].data.mobile_logo
      }
    >
      <SEO title="404: Not found" />
      <ErrorContainer>
        <ErrorContainerInner>
          <div>
            <Title>
              {
                props.data.allPrismic404PageSettings.nodes[0].data
                  .decorative_numbers[0].text
              }
              !
            </Title>
          </div>
          <div>
            <Description>
              {props.data.allPrismic404PageSettings.nodes[0].data.title[0].text}
            </Description>
          </div>
          <div>
            <SmallerDesc>
              <SmallerDescText
                dangerouslySetInnerHTML={convertHtml(
                  props.data.allPrismic404PageSettings.nodes[0].data.subtitle[0]
                    .text
                )}
              />
              <br />
              <span>
                <a href={'/'}>
                  {
                    props.data.allPrismic404PageSettings.nodes[0].data
                      .cta_text[0].text
                  }
                </a>
              </span>
            </SmallerDesc>
          </div>
        </ErrorContainerInner>
      </ErrorContainer>
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  query FourOhFourQuery {
    allPrismic404PageSettings {
      nodes {
        data {
          page_title {
            text
          }
          decorative_numbers {
            text
          }
          title {
            text
          }
          subtitle {
            text
          }
          cta_text {
            text
          }
          cta_link {
            slug
          }
          logo
          footer_logo
          mobile_logo
        }
      }
    }
  }
`;
